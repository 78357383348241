<!--
 * @Author: wy 15660501845@163.com
 * @Date: 2024-08-27 09:02:58
 * @LastEditors: WY 15660601845@163.com
 * @LastEditTime: 2024-09-09 14:07:32
 * @FilePath: /welfare-mall/welfare-mall-website/src/views/pages/golden-jubilee/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="tw-goldenJubilee">
    <div class="tw-goldenJubilee-header">
      <img class="tw-goldenJubilee-header__logo" src="https://xb-dmp-pro.oss-cn-shanghai.aliyuncs.com/upload/20240827/8b8e5f4efd9acbe360eeedc93aedd3b1.png" alt="widthFix" />
      <img class="tw-goldenJubilee-header__img" src="https://xb-dmp-pro.oss-cn-shanghai.aliyuncs.com/upload/20240827/546684744b7e00b3a9eee3c1a5cf3c6d.png" alt="widthFix" />
    </div>
    <div class="tw-goldenJubilee-center">
      <div class="tw-goldenJubilee-center__title">信息展示</div>
      <div class="tw-goldenJubilee-center__list">
        <div class="tw-list-box" v-for="(item, index) in list" :key="index" @click="router(item)">
          <div class="tw-list-box__img">
            <img :src="item.img" />
          </div>
          <div class="tw-list-box__right">
            <div class="tw-list-box__right-title">{{ item.title }}</div>
            <div class="tw-list-box__right-line"></div>
            <div class="tw-list-box__right-time">{{ item.time }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
export default {
  data() {
    return {
      list: []
    }
  },
  created() {
    axios
      .request({
        url:`${process.env.BASE_URL}/mock.json`,
        params: {
          time: new Date().getTime()
        },
        method: 'GET'
      })
      .then(res => {
        this.list = res.data.list
      })
  },
  methods: {
    router(e) {
      this.$router.push({
        path: '/article',
        query: {
          id: e.id
        }
      })
      window.scrollTo(0, 0)
    }
  }
}
</script>

<style lang="scss" scoped>
.tw-goldenJubilee {
  background: #f8f8f8;
  &-header {
    position: relative;
    &__logo {
      position: absolute;
      top: 17px;
      left: 120px;
      width: 104px;
      display: inline-block;
      &:hover {
        cursor: pointer;
      }
    }
    &__img {
      width: 100%;
      display: inline-block;
    }
  }
  .tw-goldenJubilee-center {
    width: 100%;
    padding: 94px 360px 78px;
    box-sizing: border-box;
    &__title {
      padding-bottom: 40px;
      border-bottom: 1px solid #d9d9d9;
      box-sizing: border-box;
      text-align: center;
      font-weight: bold;
      font-size: 32px;
      color: #000000;
      line-height: 48px;
      font-style: normal;
      text-transform: uppercase;
    }
    &__list {
      margin-top: 62px;
      .tw-list-box {
        width: 100%;
        margin-bottom: 48px;
        background: #ffffff;
        border-radius: 0px 6px 6px 0px;
        display: flex;
        align-items: center;
        &:hover {
          cursor: pointer;
        }
        &__img {
          img {
            width: 450px;
            height: 258px;
          }
        }
        &__right {
          flex: 1;
          padding-left: 77px;
          box-sizing: border-box;
          &-title {
            font-weight: bold;
            font-size: 24px;
            color: #000000;
            line-height: 36px;
            font-style: normal;
          }
          &-line {
            margin: 24px 0 60px;
            width: 22px;
            height: 3px;
            background: #1954ff;
          }
          &-time {
            font-weight: 400;
            font-size: 16px;
            color: #000000;
            line-height: 24px;
            font-style: normal;
          }
        }
      }
      .tw-list-box:last-child {
        margin-bottom: 0;
      }
    }
  }
  @media screen and (max-width: 800px) {
    .tw-goldenJubilee-center {
      width: 100%;
      padding: 94px 0 78px;
    }
  }
}
</style>
